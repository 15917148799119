<template>
    <main>
        <v-container>
            <v-layout align-center justify-center>
            <v-card max-width="500">
                <v-card-text>
                    <v-layout column>
                        <h3>Login</h3>
                        <v-text-field v-model = "login.email" placeholder="Email" type="email" />
                        <v-text-field v-model = "login.password" placeholder="Password" type="password" />
                        <v-btn @click = "loginUser">Login</v-btn>
                    </v-layout>
                </v-card-text>
            </v-card>
            </v-layout>
        </v-container>
    </main>
</template>

<script>
import axios from 'axios'
import { URL } from '../utils/consts'
export default {
    props:['onLogin'],
    data() {
        return {
            user: null,
            login: {
                email: '',
                password: ''
            },
        };
    },
    methods: {
        async loginUser() {
            const { data } = await axios.post(URL() + "user/login", {
                ...this.login
            })
            this.user = data
            localStorage.setItem('user', JSON.stringify(data));
            this.onLogin(data);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>