<template>
  <v-app>
    <div v-if="!loading">
      <div v-if="user">
        <router-view />
      </div>
      <div v-else>
        <Login :onLogin="onLogin" />
      </div>
    </div>
    <div v-else>
      <v-progress-circular indeterminate/>
    </div>
  </v-app>
</template>
<script>
import axios from 'axios';
import { URL } from './utils/consts'
import Login from "./views/Login";
import { getUser } from "./utils/login";
export default {
  components: {
    Login,
  },
  data() {
    return {
      user: null,
      loading: true,
    };
  },
  methods: {
    onLogin(user) {
      this.user = user;
      this.loading = false;
    },
    async transfer(token) {
      const { data } = await axios.get(URL() + `user/info`, {
                    headers: {
                    Authorization: "Bearer " + token,
                    }
                })
      const user = {
        ...data,
        token 
      }
      localStorage.setItem('user', JSON.stringify(user));
      this.onLogin(user);
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      this.$router.replace({ query });
    }
  },
  mounted() {
    this.user = getUser();
    setTimeout(() => {
      if (this.$route.query.token) {
        this.transfer(this.$route.query.token)
      } else {
        this.loading = false;
      }
    }, 500)
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
.appbar {
  background: linear-gradient(121.03deg, #55b6e2 15.79%, #604fe6 64.07%);
}
.bottomShadow {
  background: linear-gradient(0deg, #50000000 45%, #00000000 100%);
  color: #fff;
  width: 100%;
}
h4 {
  font-weight: 300
}
</style>
