import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


import VideoPlayer from 'vue-videojs7'

Vue.use(VideoPlayer)

export default new Vuetify({
});
