import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/streaming/:id',
    name: 'Streaming',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Streaming.vue')
  },
  {
    path: '/create/stream',
    name: 'Create Stream',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateStream.vue')
  },
  {
    path: '/stream/:id',
    name: 'Viewer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Viewer.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/admin/Admin.vue')
      },
      {
        path: 'reports',
        name: 'Reported',
        component: () => import('../views/admin/ReportedRooms.vue')
      },
      {
        path: 'challenges',
        name: 'SuggestedChallenges',
        component: () => import('../views/admin/SuggestedChallenges.vue')
      },
      {
        path: 'logs',
        name: 'Logs',
        component: () => import('../views/admin/logs/Logs.vue'),
        children: [
          {
            path: ':id',
            name: 'SpecificLog',
            component: () => import('../views/admin/logs/Logs.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/admin/room/:id',
    name: 'Room',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Room.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
