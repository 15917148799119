<template>
<main>
  <v-layout class="appbar pa-16" align-center justify-center wrap>
    <v-flex>
      <img alt="Vue logo" src="https://s3.eu-central-1.amazonaws.com/ezmid.konektr.development/uploads/cc/10/cc1023c0-026e-11eb-a742-19aedb36cbbb-LogoHome.svg">
    </v-flex>
    <v-flex>
    </v-flex>
    <v-flex>
      <v-layout wrap align-center justify-end>
        <v-avatar>
          <img :src = "user.image"/>
        </v-avatar>
        <h4 style = "color: white; padding-left: 16px">{{user.username}}</h4>
        <v-btn icon color = "white" @click = "logout">
          <v-icon>
            mdi-logout
          </v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
  <v-container>
    <v-layout column>
    <v-layout row align-center pt-8 pb-8><h1 class = "font-weight-light">Available streams</h1><v-spacer/><v-btn text @click = "$router.push('/create/stream')" v-if = "user.userType === 'PUBLISHER'">Create new one</v-btn></v-layout>
    <v-layout wrap>
      <v-flex style = "width:590px">
        <v-card v-if = "stream" style = "position:relative; width:590px; padding:0; margin: 0 auto;">
            <!--<video
                  id="video"
                  autoplay
                  width="590px"
                  height="890px"
                  style = "object-fit: cover;"
                  ref="video"
                  :src="streamUrl(stream)"
                  loop
                  muted
                ></video>-->
                <video-player 
                  ref="videoPlayer"
                  class="vjs-custom-skin"
                  id="video_preview"
                  :class="{
                    hidden: !stream.cover.video
                  }" >
                </video-player>
                <v-img v-if = "stream && stream.cover.image" :src = "stream.cover.image"/>
                
                <div class = "overlay" />
                <div class = "overlay" />
                <v-layout column style = "position: absolute; top:0; left:0; right:0;bottom:0; padding-bottom: 32px">
                  <v-layout column class = "viewers" align-center justify-center>
                    <v-icon color="white" :size="20">mdi-eye</v-icon>
                    <span style = "font-size: 12px">{{ stream.viewers }}</span>
                  </v-layout>
                  
                  <v-spacer/>
                  <v-flex>
                    <v-layout row style = "margin: 16px;" v-if = "countdown">
                      <v-flex class = "glass">
                        {{ countdown.days }} d
                      </v-flex>
                      <v-flex class = "glass">
                        {{ countdown.hours }} h
                      </v-flex>
                      <v-flex class = "glass">
                        {{ countdown.minutes }} m
                      </v-flex>
                      <v-flex class = "glass">
                        {{ countdown.seconds }} s
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-spacer/>
                  <v-flex shrink style = "position:relative">
                    <div class = "avatar-absolute">
                      <v-avatar>
                        <img  :src = "stream.user.image"/>
                      </v-avatar>
                    </div>
                    <div class = "backdrop user">
                      {{ stream.user.username }}
                    </div>
                  </v-flex>
                  <v-flex shrink style = "position:relative; margin-top: 15px">
                     <div class = "backdrop name">
                      {{ stream.name }}
                    </div>
                  </v-flex>
                  <v-flex shrink style = "margin-top: 10px; padding: 10px 5%">
                    <v-layout wrap>
                      <v-flex xs6 style = "padding-right: 5px">
                        <v-btn class = "skipButton" @click = "skip()">
                          SKIP
                        </v-btn>
                      </v-flex>
                      <v-flex xs6 style = "padding-left: 5px">
                        <v-btn class = "enterButton" @click = "open(stream)">
                          ENTER
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
            <div style = "position:absolute; right:5px; top: 5px;">
            <v-chip
              color="#FF0000"
              dark
              v-if = "stream.live"
            >Live</v-chip>
          </div>
        </v-card>
      </v-flex> 
    </v-layout>
    
    </v-layout>
  </v-container>
</main>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { URL } from '../utils/consts'
import { getUser } from "../utils/login";
import moment from 'moment';

export default {
  name: 'Home',
  data() {
    return {
      interval: null,
      streams: [],
      stream: null,
      selected: 0,
      count: 0,
      streamOption: {},
      countdown: null
    }
  },
  methods: {
    open(stream) {
      const { id } = stream
      const user = getUser()
      if (stream.userId === user.id) {
        this.$router.push(`/streaming/${id}`)
      } else {
        this.$router.push(`/stream/${id}`)
      }
    },
    skip() {
      this.stream = null
      setTimeout(() => {
        this.selected ++;
        if (this.selected >= this.streams.length) {
          this.selected = 0;
        }
        this.streamOption = {
          src: this.link(this.streams[this.selected]),
          preload: true,
          autoplay: true,
          isLoop: true,
          playsinline: false,
          crossOrigin: true,
        type: 'application/x-mpegurl',
      }
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(this.streamOption)
      // this.player.load()
      this.player.play()
        this.stream = this.streams[this.selected]
      }, 300)
    },
    async fetchStreams() {
      const { data } = await axios.get(URL() + "rooms", {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
      this.streams = data.items
      this.stream = data.items[0]

      setTimeout(() => {
        if (this.stream) {
            this.streamOption = {
                src: this.link(this.stream),
                preload: true,
                autoplay: true,
                isLoop: true,
                playsinline: false,
                crossOrigin: true,
              type: 'application/x-mpegurl',
            }
            this.player.reset()
            this.player.src(this.streamOption)
            this.player.load()
            setTimeout(() => {
              this.player.play()
            }, 1000)
        }
      }, 500)

      this.count += data.count
    },
    resetCountdown() {
      this.countdown = null
    },
    async loginUser() {
      const { data } = await axios.post(URL() + "user/login", {
        ...this.login
        })
      this.user = data
      localStorage.setItem('user', JSON.stringify(data));
    },
    logout () {
      localStorage.removeItem('user')
      window.location.reload()
    },
    link(stream) {
      if (!stream) return '';
      if (!stream.cover.video) return '';
      let link = stream.cover.video.url + '?';
      link = link + stream.cover.video.cookies.map(e => `${e.name.replace('CloudFront-','')}=${e.value}`).join('&')
      return link
    }
  },
  mounted() {
    const user = localStorage.getItem('user');
    if (user) {
      this.user = JSON.parse(user)
    }
    setTimeout(() => {
      this.fetchStreams()
    }, 1000)
    setInterval(() => {
      if (!this.stream || !this.stream.scheduledAt) 
        return this.resetCountdown()
      const now = moment().utc();
      const start = moment(this.stream.scheduledAt)

      if (start.isBefore(now)) return this.resetCountdown()

      const days = start.diff(now, 'days');
      const hours = Math.floor(start.diff(now, 'hour') % 24)
      const minutes = Math.floor(start.diff(now, 'minute') % 60)
      const seconds = Math.floor(start.diff(now, 'second') % 60)

      this.$set(this, 'countdown', {
        days, hours, minutes, seconds
      })
    }, 1000)
  },
  computed: {
    user() {
      return getUser()
    },
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style>
#video_preview {
  width:590px;
  height:890px; 
}
.overlay {
  position:absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  transform:rotate(180deg);
  opacity: .94;
  background: linear-gradient(180deg, #0C0731 -20.84%, rgba(12, 7, 49, 0) 73.72%);;
}
.overlay:nth-last-of-type(even) {
  position:absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  opacity: .94;
  background: linear-gradient(0deg, #0C0731 -20.84%, rgba(12, 7, 49, 0) 73.72%);;
}
.backdrop {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  color: #FFF;
  border-radius: 6px;
}
.user {
  font-weight: 800;
font-size: 18px;
line-height: 25px;
width: calc(90% - 24px); 
margin-left: calc(5% + 24px); 
padding: 13px 35px; 

}
.name {
  font-weight: 800;
font-size: 18px;
line-height: 25px;
width: 90%; 
margin: 0 auto; 
padding: 13px 35px; 
font-weight: 900;
font-size: 28px;
}
.avatar-absolute {
  display:inline-block;
  position: absolute;
  left: 5%;
  z-index: 1
}
.skipButton {
  background: linear-gradient(180deg, #78B8E1 0%, #846CFA 100%);
  font-weight: 800;
font-size: 14px;
color: #FFF!important;
width:100%;
border-radius: 26.5px;
}
.enterButton {
  background: linear-gradient(180deg, #D568E1 0%, #E4928A 100%);
  font-weight: 800;
font-size: 14px;
color: #FFF!important;
width:100%;
border-radius: 26.5px;
}
.viewers {
  position: absolute;
  right:15px;
  top: 120px;
  width:47px;
  height:47px;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

  .vjs-custom-skin video, .video-js {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .hidden {
    display: none;
  }

  .glass {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    color: #FFF;
    /* Note: backdrop-filter has minimal browser support */

    padding: 10px;
    margin: 15px;

    font-style: normal;
    font-weight: 900;
    font-size: 28px;

    border-radius: 12px;
  }
</style>